export default {
  year: 2014,
  edition: "제 10회",
  date: "2014년 11월 20일 월요일",
  time: "오후 7시 ~ 10시",
  location: "리치과학관 R912 / R914",
  links: {
    scoreboards: {
      Master: "https://www.acmicpc.net/contest/scoreboard/74",
      Champion: "https://www.acmicpc.net/contest/scoreboard/75",
    },
    problems: {
      BOJ: {
        Master: "https://www.acmicpc.net/category/detail/1299",
        Champion: "https://www.acmicpc.net/category/detail/1300",
      },
    },
  },
};
