export default {
  year: 2016,
  edition: "제 12회",
  date: "2016년 11월 21일 월요일",
  time: "오후 7시 ~ 10시",
  location: "리치과학관 R912 / R914",
  links: {
    scoreboards: {
      Master: "https://www.acmicpc.net/contest/board/204",
      Champion: "https://www.acmicpc.net/contest/board/203",
    },
    problems: {
      BOJ: {
        Master: "https://www.acmicpc.net/category/detail/1577",
        Champion: "https://www.acmicpc.net/category/detail/1576",
      },
    },
  },
  contests: [
    {
      title: "제 12회 총장배 서강대학교 프로그래밍 대회 Master",
      columns: ["#", "=", "이름"],
      data: [
        ["1", "6", "최윤영"],
        ["2", "5", "안성진"],
        ["3", "5", "조남준"],
        ["4", "4", "안향빈"],
        ["5", "3", "김영진"],
        ["6", "3", "민지우"],
      ],
      award: ["gold", "silver", "silver", "bronze", "bronze", "bronze"],
      links: [],
    },
    {
      title: "제 12회 총장배 서강대학교 프로그래밍 대회 Champion",
      columns: ["#", "=", "이름"],
      data: [
        ["1", "7", "송은호"],
        ["2", "6", "박동규"],
        ["3", "4", "최광희"],
        ["4", "3", "박상욱"],
        ["5", "3", "조정래"],
        ["6", "3", "김현배"],
        ["7", "2", "김정래"],
      ],
      award: [
        "winner",
        "gold",
        "silver",
        "silver",
        "bronze",
        "bronze",
        "bronze",
      ],
      links: [],
    },
  ],
};
