export default {
  year: 2021,
  contests: [
    {
      title: "2021 ICPC Asia Seoul Regional Contest",
      columns: ["#", "=", "팀", "멤버"],
      data: [
        ["19", "6", "no gum cant win", "강효규, 이상원, 조원빈"],
        ["45", "2", "StarPlus", "김정모, 박준서, 전해성"],
      ],
      award: [],
      links: [
        ["공식 사이트", "http://icpckorea.org/2021-seoul/regional"],
        ["스코어보드", "http://static.icpckorea.net/2021/scoreboard_regional"],
      ],
      review: [
        ["강효규(djs100201)", "https://physicsmathcoumputer.tistory.com/54"],
      ],
    },
    {
      title: "2021 ICPC Asia Seoul Preliminary Contest",
      columns: ["#", "=", "팀", "멤버"],
      data: [
        ["24", "6", "no gum cant win", "강효규, 이상원, 조원빈"],
        ["55", "3", "StarPlus", "김정모, 박준서, 전해성"],
        ["61", "3", "Bronzeking123", "강다혜, 오승철, 천성원"],
        ["62", "3", "BangDream", "박건, 손기령, 이민희"],
        ["75", "3", "temp", "박민성, 박정규, 이선호"],
        ["86", "3", "JWA", "김서인, 신동준, 좌민서"],
        ["88", "3", "philmath12", "김민형, 이호현, 한상필"],
        ["108", "2", "Dog River", "권지은, 김성현, 김창민"],
        ["151", "2", "SG202021", "이동협, 채빈, 최원창"],
        ["154", "2", "HyeoplCoSil", "김동건, 성주희, 조성훈"],
        ["235", "1", "seoi2choo", "이서일, 이아륜, 정서영"],
      ],
      award: ["advanced", "advanced"],
      links: [
        ["공식 사이트", "http://icpckorea.org/2021-seoul/preliminary"],
        [
          "스코어보드",
          "http://static.icpckorea.net/2021/scoreboard_preliminary",
        ],
      ],
      review: [["이민희(minigimbob)", "https://www.minigb.io/111"]],
    },
    {
      title: "ICPC World Finals Moscow",
      columns: ["#", "=", "팀", "멤버"],
      data: [["87", "3", "Redshift", "박수현, 이상원, 이준석"]],
      award: [],
      links: [
        ["공식 사이트", "https://worldfinals.icpc.global"],
        ["스코어보드", "https://pc2.ecs.baylor.edu/scoreboard"],
      ],
      review: [
        [
          "임지환(raararaara)",
          "https://blog.naver.com/raararaara/222519851530",
        ],
      ],
    },
    {
      title: "SUAPC 2021 Summer",
      columns: ["#", "=", "팀", "멤버"],
      data: [
        ["1", "8", "또또 나만 모르는 웰노운 낸다", "강효규, 이상원, 조원빈"],
        ["2", "8", "스파르타Rebro클럽", "박재형, 오재석, 진상우"],
        ["7", "5", "상금 못 받으면 회식 못해요 ㅠㅠ", "김정모, 김준우, 박준서"],
        ["8", "5", "philmath12", "김민형, 이호현, 한상필"],
        ["9", "5", "ECM", "권지은, 김성현, 김창민"],
        ["10", "5", "재국이와 아이둘", "조유화, 조재국, 한덕규"],
      ],
      award: ["gold", "silver"],
      links: [
        ["공식 사이트", "https://suapc.kr"],
        ["스코어보드", "https://www.acmicpc.net/contest/spotboard/678"],
      ],
      review: [],
    },
    {
      title: "2021 ICPC Sinchon Summer Algorithm Camp Contest - Intermediate",
      columns: ["#", "=", "닉네임", "이름"],
      data: [["1", "4", "wbcho0504", "조원빈"]],
      award: ["gold"],
      links: [
        ["공식 사이트", "https://icpc-sinchon.io/campcontest"],
        ["스코어보드", "https://www.acmicpc.net/contest/spotboard/677"],
      ],
      review: [],
    },
    {
      title: "2021 ICPC Sinchon Summer Algorithm Camp Contest - Novice",
      columns: ["#", "=", "닉네임", "이름"],
      data: [
        ["1", "6", "jakads", "김창민"],
        ["3", "4", "dart", "김성현"],
      ],
      award: ["gold", "bronze"],
      links: [
        ["공식 사이트", "https://icpc-sinchon.io/campcontest"],
        ["스코어보드", "https://www.acmicpc.net/contest/spotboard/676"],
      ],
      review: [],
    },
    {
      title: "UCPC 2021 본선",
      columns: ["#", "=", "팀", "멤버"],
      data: [
        ["12", "6", "Redshift", "박수현, 이준석, 전해성"],
        ["41", "4", "raararaara 팬 클럽", "강효규, 이상원, 조원빈"],
      ],
      award: ["special"],
      links: [
        ["공식 사이트", "https://ucpc.me"],
        ["스코어보드", "https://www.acmicpc.net/contest/spotboard/670"],
      ],
      review: [],
    },
    {
      title: "Google Code Jam Round 2 2021",
      columns: ["#", "=", "닉네임", "이름"],
      data: [
        ["1,328 / 🇰🇷75", "56", "shiftpsh", "박수현"],
        ["1,988 / 🇰🇷99", "43", "Rebro", "박재형"],
      ],
      award: [],
      links: [
        ["공식 사이트", "https://codingcompetitions.withgoogle.com/codejam"],
        [
          "스코어보드",
          "https://codingcompetitions.withgoogle.com/codejam/round/0000000000435915",
        ],
      ],
      review: [],
    },
    {
      title: "Google Code Jam Round 1A 2021",
      columns: ["#", "=", "닉네임", "이름"],
      data: [
        ["105 / 🇰🇷8", "75", "semteo04", "이준석"],
        ["842 / 🇰🇷55", "60", "shiftpsh", "박수현"],
        ["1,273 / 🇰🇷71", "54", "Rebro", "박재형"],
      ],
      award: [],
      links: [
        ["공식 사이트", "https://codingcompetitions.withgoogle.com/codejam"],
        [
          "스코어보드",
          "https://codingcompetitions.withgoogle.com/codejam/round/000000000043585d",
        ],
      ],
      review: [],
    },
    {
      title: "Google Hash Code 2021 Online Qualification Round",
      columns: ["#", "=", "팀", "멤버"],
      data: [
        [
          "1,111 / 🇰🇷24",
          "9,480,876",
          "Redshift",
          "박수현, 이준석, 전해성, 최백준",
        ],
        ["3,075 / 🇰🇷46", "8,678,912", "MINI-CHO-BOB", "강효규, 이민희, 조원빈"],
        ["3,254 / 🇰🇷47", "8,531,902", "Team Gumgood", "김주현, 이상원"],
      ],
      award: [],
      links: [
        ["공식 사이트", "https://codingcompetitions.withgoogle.com/hashcode"],
        [
          "스코어보드",
          "https://hashcodejudge.withgoogle.com/#/rounds/5879728443490304/scoreboards",
        ],
      ],
      review: [],
    },
    {
      title: "SUAPC 2021 Winter",
      columns: ["#", "=", "팀", "멤버"],
      data: [
        ["2", "9", "151719", "이준석, 전해성, 정재훈"],
        ["3", "9", "No Orange cant win", "김관우, 박재형, 이윤제"],
        ["4", "8", "Terra", "이동주, 이민희, 조원빈"],
        ["6", "7", "app", "강다혜, 강문해, 전민수"],
        ["7", "7", "전생했더니 박건이었던 건에 대하여", "강효규, 박건, 손기령"],
        ["8", "6", "서수삼", "오재석, 유호영, 홍서준"],
      ],
      award: ["silver", "silver", "bronze", "bronze", "special", "special"],
      links: [
        ["문제", "https://www.acmicpc.net/category/detail/2446"],
        ["스코어보드", "https://www.acmicpc.net/contest/spotboard/586"],
      ],
      review: [
        ["박재형(pjh6792)", "https://rebro.kr/114"],
        ["전해성(seastar105)", "https://seastar105.tistory.com/80"],
        ["강효규(djs100201)", "https://physicsmathcoumputer.tistory.com/25"],
        ["이윤제(yjyj1027)", "https://blog.naver.com/yjyj1027/222267208478"],
      ],
    },
    {
      title: "2021 ICPC Sinchon Winter Algorithm Camp Contest - Intermediate",
      columns: ["#", "=", "닉네임", "이름"],
      data: [
        ["1", "6", "wbcho0504", "조원빈"],
        ["2", "6", "pjh6792", "박재형"],
      ],
      award: ["gold", "silver"],
      links: [
        ["문제", "https://www.acmicpc.net/category/detail/2429"],
        ["스코어보드", "https://www.acmicpc.net/contest/spotboard/589"],
      ],
      review: [["박재형(pjh6792)", "https://rebro.kr/113"]],
    },
    {
      title: "2021 ICPC Sinchon Winter Algorithm Camp Contest - Novice",
      columns: ["#", "=", "닉네임", "이름"],
      data: [["3", "4", "whitehorse", "오재석"]],
      award: ["bronze"],
      links: [
        ["문제", "https://www.acmicpc.net/category/detail/2428"],
        ["스코어보드", "https://www.acmicpc.net/contest/spotboard/588"],
      ],
      review: [],
    },
  ],
};
