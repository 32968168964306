export default {
  year: 2011,
  edition: "제 7회",
  links: {
    problems: {
      BOJ: {
        Challenger: "https://www.acmicpc.net/workbook/view/24",
        Champion: "https://www.acmicpc.net/workbook/view/25",
      },
    },
  },
};
