export default {
  year: 2018,
  edition: "제 14회",
  date: "2018년 11월 23일 금요일",
  time: "오후 7시 ~ 10시",
  location: "다산관 D104",
  links: {
    scoreboards: {
      Master: "https://www.acmicpc.net/contest/board/368",
      Champion: "https://www.acmicpc.net/contest/board/379",
    },
    problems: {
      BOJ: {
        Master: "https://www.acmicpc.net/category/detail/1961",
        Champion: "https://www.acmicpc.net/category/detail/1962",
      },
    },
  },
  authors: ["이준석", "최윤영", "최지원", "손정연", "윤기영", "정진욱"],
  contests: [
    {
      title: "SPC 2018 Master",
      columns: ["#", "=", "이름"],
      data: [
        ["1", "6", "박수현"],
        ["2", "5", "이용욱"],
        ["3", "5", "최민영"],
        ["4", "4", "이진훈"],
        ["5", "4", "전민수"],
        ["6", "4", "임지환"],
      ],
      award: ["gold", "silver", "silver", "bronze", "bronze", "bronze"],
      links: [],
    },
    {
      title: "SPC 2018 Champion",
      columns: ["#", "=", "이름"],
      data: [
        ["1", "5", "엄태경"],
        ["2", "4", "임창현"],
        ["3", "2", "김규진"],
        ["4", "2", "김주훈"],
        ["5", "2", "송인아"],
        ["6", "2", "황기덕"],
        ["7", "2", "이동승"],
      ],
      award: [
        "winner",
        "gold",
        "silver",
        "silver",
        "bronze",
        "bronze",
        "bronze",
      ],
      links: [],
    },
  ],
};
